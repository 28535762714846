import { boot } from "quasar/wrappers";
import VueLogger from "vuejs3-logger";

export default boot(({ app }) => {
  const isProduction = process.env.VUE_APP_ENV === "prod";

  const options = {
    isEnabled: !isProduction,
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: "|",
    showConsoleColors: true,
  };

  app.use(VueLogger, options);
});
