import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("layouts/LayoutsIndex.vue"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () => import("pages/Dashboard.vue"),
      },
      {
        path: "/period",
        name: "period",
        component: () => import("pages/dashboard/period/PeriodDetails.vue"),
        children: [
          {
            path: "",
            name: "daily",
            component: () => import("pages/dashboard/period/tabs/Daily.vue"),
          },
          {
            path: "weekly",
            name: "weekly",
            component: () => import("pages/dashboard/period/tabs/Weekly.vue"),
          },
        ],
      },
      {
        path: "/patients",
        name: "patients",
        component: () => import("pages/Patients.vue"),
      },
      {
        path: "/patient/:userKey",
        name: "patient",
        component: () => import("pages/patients/Patient.vue"),
        props: true,
        children: [
          {
            path: "",
            name: "patient-info",
            component: () => import("pages/patients/tabs/PatientInfo.vue"),
          },
          {
            path: "frequency-calendar",
            name: "frequency-calendar",
            component: () =>
              import("pages/patients/tabs/FrequencyCalendar.vue"),
          },
          {
            path: "self-talk",
            name: "self-talk",
            component: () => import("pages/patients/tabs/SelfTalk.vue"),
          },
          {
            path: "self-talk-plus",
            name: "self-talk-plus",
            component: () => import("pages/patients/tabs/SelfTalkPlus.vue"),
          },
          {
            path: "breathing",
            name: "breathing",
            component: () => import("pages/patients/tabs/Breathing.vue"),
          },
          {
            path: "mind-garden",
            name: "mind-garden",
            component: () => import("pages/patients/tabs/MindGarden.vue"),
          },
          {
            path: "idea-memo",
            name: "idea-memo",
            component: () => import("pages/patients/tabs/IdeaMemo.vue"),
          },
        ],
      },
      {
        path: "/user-invitation",
        name: "user-invitation",
        component: () => import("pages/UserInvitation.vue"),
      },
      {
        path: "/programs",
        name: "programs",
        component: () => import("pages/Programs.vue"),
        children: [
          {
            path: "",
            name: "self-talk-contents",
            component: () => import("pages/programs/tabs/SelfTalkContents.vue"),
          },
          {
            path: "idea-memo-stats",
            name: "idea-memo-stats",
            component: () =>
              import("pages/programs/tabs/IdeaMemoStatistics.vue"),
          },
        ],
      },
      // {
      //   path: "/notices",
      //   name: "notices",
      //   component: () => import("pages/Notices.vue"),
      //   children: [
      //     {
      //       path: "enrollment",
      //       name: "notice-enrollment",
      //       component: () => import("pages/notice/NoticeEnrollment.vue")
      //     },
      //   ]
      // },
      {
        path: "/notice-list",
        name: "notice-list",
        component: () => import("pages/Notices.vue"),
        // props: true,
        // children: [
        //   {
        //     path: "",
        //     name: "notice",
        //     component: () => import("pages/notice/Notice.vue")
        //   }
        // ]
      },
      {
        path: "/notice-enrollment",
        name: "notice-enrollment",
        component: () => import("pages/notice/NoticeEnrollment.vue")
      },
      {
        path: "/notice/:uuid",
        name: "notice",
        component: () => import("pages/notice/Notice.vue")
      },

      // {
      //   path: "/notice",
      //   name: "notice",
      //   component: () => import("pages/notice/NoticeList.vue"),
      //   props: true,
      //   children: [
      //     // {
      //     //   path: "",
      //     //   name: "notice-list",
      //     //   component: () => import("pages/notice/Notices.vue")
      //     // },
      //     {
      //       path: "enrollment",
      //       name: "notice-enrollment",
      //       component: () => import("pages/notice/NoticeEnrollment.vue")
      //     }
      //   ]
      // }
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/ErrorNotFound.vue"),
  },
];

export default routes;
