import { Notify } from "quasar";

export const notificationType = {
  positive: "positive",
  negative: "negative",
  warning: "warning",
  info: "info",
} as const;
export type NotificationType = keyof typeof notificationType;

Notify.setDefaults({
  position: "top-right",
  closeBtn: true,
});

Notify.registerType(notificationType.positive, {
  color: notificationType.positive,
  icon: "check",
});

Notify.registerType(notificationType.negative, {
  color: notificationType.negative,
  icon: "report",
});

Notify.registerType(notificationType.info, {
  color: notificationType.info,
  icon: "info",
});

Notify.registerType(notificationType.warning, {
  color: notificationType.warning,
  icon: "warning",
});
