import { boot } from "quasar/wrappers";
import useHaiiExAuth from "src/composables/server/useHaiiExAuth";

export default boot(async () => {
  const exAuth = useHaiiExAuth();
  const isProduction = process.env.VUE_APP_ENV === "prod";
  const signInRedirectURI = window.location.origin;

  // ServiceNameType 에 추가
  exAuth.init({ serviceName: "alx-dtx", isProduction, signInRedirectURI });
  await exAuth.manageToken(true);
});
