const $Common = {
  all: "전체",
  cancel: "취   소",
  delete: "삭제",
  modification: "수정",
  cancelModification: "수정 취소",
  confirmDelete: "삭제하면 복구할 수 없습니다. 계속하시겠습니까?",
  message: "메세지",
  greeting: "안녕하세요!",
  goodbye: "안녕히 가세요!",
  none: "없음",
  ok: "확   인",
  or: "또는",
  subtitle: "",
  title: "",
  unknown: "모름",
  userManagement: "유저 관리",
  serviceName: "Anzeilax",
  language: "언어",
  dashboard: "대시보드",
  programs: "프로그램",
  content: "",
  save: "저장하기",
  points: "점",

  darkMode: "다크모드",
  logout: "로그아웃 하시겠습니까?",

  // dashboard
  subjectStatus: "피험자 현황",
  subjectCompleteRate: "피험자 완료율",
  subjectPeriodStatus: "피험자 기간별 진행 현황",
  subjectPeriodCompliance: "피험자 기간별 순응도",
  inDetails: "자세히보기",
  dau: "일일 활성 사용자 (DAU)",
  subjectMonitored: "모니터링 대상자",
  showAll: "전체보기",

  // 날짜 관련
  weekdaysLong: "일요일 월요일 화요일 수요일 목요일 금요일 토요일",
  weekdaysShort: "일 월 화 수 목 금 토",
  today: "오늘",
  yesterday: "어제",
  weekly: "주간",
  daily: "일간",
  monthly: "월간",
  year: "년(4자)",
  month: "월",
  day: "일",
  second: "초",
  before: "이전",

  // 환자 관련
  displayName: "이름",
  patientCode: "이용권 번호",
  age: "나이",
  birthDt: "생년월일",
  gender: "성별",
  state: "상태",
  lastEvaluationDt: "마지막 평가",
  male: "남",
  female: "여",
  phone: "휴대전화",
  address: "주소",
  firstOutbreakDt: "최초 발병일",
  recentVisitDt: "최근 방문일",
  diseaseType: "질병 타입",
  disabilityType: "장애 타입",
  authenticationNumber: "인증코드",
  email: "이메일",
};

export const ko = {
  AppBar: {
    ...$Common,
  },
  DrawerSetting: {
    ...$Common,
  },
  LanguageSet: {
    ...$Common,
  },
  DarkModeSet: {
    ...$Common,
  },
  DrawerMenuList: {
    ...$Common,
    patientManagement: "환자 관리",
    notice: "공지사항",
    profile: "프로필",
    logout: "로그아웃",
    userList: "유저 리스트",
    userAdd: "유저 추가",
  },
  Dashboard: {
    ...$Common,
  },
  Patients: {
    ...$Common,
  },
  Programs: {
    ...$Common,
  },
  LoginLayoutHeader: {
    ...$Common,
  },
};
