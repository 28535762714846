import { defineStore } from "pinia";
import { computed, Ref } from "vue";
import { useLocalStorage } from "@vueuse/core";

type SettingType = {
  locale: string;
  darkMode: boolean;
};

export const useSettingStore = defineStore("setting", () => {
  const setting: Ref<SettingType> = useLocalStorage("anz/setting", {
    locale: "ko",
    darkMode: false,
  });

  const getSetting = computed(() => {
    return setting.value;
  });

  function setSetting(newSetting: Partial<SettingType>) {
    setting.value = { ...setting.value, ...newSetting };
  }

  return {
    setting,
    setSetting,
    getSetting,
  };
});
