import { ko } from "src/i18n/ko";

const $Common = {
  all: "All",
  cancel: "Cancel",
  modification: "Modification",
  cancelModification: "Cancel modification",
  confirmDelete: "Are you sure you want to delete this?",
  delete: "Delete",
  message: "Message",
  greeting: "Hi There!",
  goodbye: "Goodbye!",
  none: "None",
  ok: "OK",
  or: "or",
  subtitle: "",
  title: "",
  unknown: "Unknown",
  content: "",
  save: "Save",

  userManagement: "User management",
  serviceName: "Anzeilax",
  language: "Language",
  dashboard: "Dashboard",
  programs: "Programs",
  points: "points",

  darkMode: "Dark Mode",
  logout: "Are you sure you want to logout?",

  // dashboard
  subjectStatus: "Current State of Subject",
  subjectCompleteRate: "Complete Rate of Subject",
  subjectPeriodStatus: "State By Period",
  subjectPeriodCompliance: "Compliance By Period",
  inDetails: "In Details",
  dau: "Daily Active Users",
  subjectMonitored: "Subject Monitored",
  showAll: "See All",

  // 날짜 관련
  weekdaysLong: "Sunday Monday Tuesday Wednesday Thursday Friday Saturday",
  weekdaysShort: "Sun Mon Tue Wed Thu Fri Sat",
  today: "Today",
  yesterday: "Yesterday",
  weekly: "Weekly",
  daily: "Daily",
  monthly: "Monthly",
  year: "Year",
  month: "Month",
  day: "Day",
  second: "second",
  before: "before",

  // 환자 관련
  displayName: "Name",
  patientCode: "Patient number",
  age: "Age",
  birthDt: "Birth date",
  gender: "Gender",
  state: "State",
  lastEvaluationDt: "Last evaluation",
  male: "male",
  female: "female",
  phone: "Phone",
  address: "address",
  firstOutbreakDt: "first outbreak date",
  recentVisitDt: "recent visit",
  diseaseType: "disease type",
  disabilityType: "disability type",
  authenticationNumber: "Authentication number",
  email: "email",
};

export const en: typeof ko = {
  AppBar: {
    ...$Common,
  },
  DrawerSetting: {
    ...$Common,
  },
  LanguageSet: {
    ...$Common,
  },
  DarkModeSet: {
    ...$Common,
  },
  DrawerMenuList: {
    ...$Common,
    patientManagement: "Patient Management",
    notice: "Notice",
    profile: "Profile",
    logout: "Logout",
    userList: "User list",
    userAdd: "User add",
  },
  Dashboard: {
    ...$Common,
  },
  Patients: {
    ...$Common,
  },
  Programs: {
    ...$Common,
  },
  LoginLayoutHeader: {
    ...$Common,
  },
};
